import React from "react";
import "../assets/_footer.scss";

export default function Home() {
  return (
    <main>
      <div className="foo">
        <div className="at">© 2024 CPMORES, All Rights Reserved</div>
      </div>
    </main>
  );
}
