import * as React from "react";
import "../assets/porvider.scss";
import { ThemeProvider } from "@mui/joy";
import theme from "./theme.js";
import TimeDisplay from "./time.js";
import Title from "./title.js";

export default function pastlive() {
  return (
    <ThemeProvider theme={theme}>
      <TimeDisplay />
      <header>
        <Title title="Past" />
        <nav>
          <a href="http://www.pastonline.cc/home">Secret Garden</a>
          <a href="http://www.pastonline.cc/home?cat=movie">Movie Seabed</a>
          <a href="http://www.pastonline.cc/home?cat=web">
            Witness another life
          </a>
          <a href="http://www.pastonline.cc/home?cat=note">Mirror of Life</a>
        </nav>
        <Title title="Online" />
      </header>
      <main>
        <aside>
          <write>
            Dear Ada
            <br />
          </write>
          <write>
            &nbsp;&nbsp;&nbsp;&nbsp;I believe that 'I think, therefore I am' is
            incorrect. I am not an inherently unchanging subject, but rather
            something that is constructed through the{" "}
            <a href="https://github.com/cpmores">
              <out>portrayal</out>
            </a>{" "}
            of others. Freedom implies a broad atheism, the deconstruction of
            idols (which is a survival strategy in the age of the internet), and
            a playful engagement with cultural and linguistic systems. Similar
            to{" "}
            <a href="http://www.pastonline.cc/home?cat=note">
              <out>music</out>
            </a>{" "}
            or{" "}
            <a href="http://www.pastonline.cc/home?cat=movie">
              <out>film</out>
            </a>
            , if one does not engage in dialogue and collaboration with them,
            what is said is merely a representation of the cultural system. That
            is why I love{" "}
            <a href="http://www.pastonline/home?cat=web">
              <out>coding</out>
            </a>
            ; through creation and dialogue with others, it is the path to
            freedom.
          </write>
        </aside>
      </main>
    </ThemeProvider>
  );
}
