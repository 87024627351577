import React, { useEffect, useState } from "react";
import "../assets/home.scss";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

axios.defaults.withCredentials = true;
export default function Home() {
  const [posts, setPosts] = useState([]);
  const cat = useLocation().search;

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("Yes");
        console.log("Query parameters:", cat);
        const res = await axios.get(
          `https://my-blog-cpmores-gpvl.vercel.app/api/posts${cat}`
        );
        setPosts(res.data);
        console.log(res.data);
      } catch (err) {
        console.log("No");
        setPosts([]);
        console.log(err);
      }
    };
    fetchData();
  }, [cat]);
  return (
    <main className="homepage">
      <div className="posts">
        {!posts ? (
          <Spin
            indicator={
              <LoadingOutlined style={{ fontSize: 48 }} fullscreen spin />
            }
          />
        ) : (
          posts
            .slice()
            .reverse()
            .map((post) => (
              <div className="post" key={post.id}>
                <div className="img">
                  <img src={post.img} alt={post.title} />
                </div>
                <div className="content">
                  <Link
                    to={`/post/${post.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <h1>{post.title}</h1>
                  </Link>
                  <p>{post.desc}</p>
                  <button>
                    <Link to={`/post/${post.id}`}>Read More</Link>
                  </button>
                </div>
              </div>
            ))
        )}
      </div>
    </main>
  );
}
