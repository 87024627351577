import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "../assets/mdeditor.scss";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css";

const MdEditor = ({ content }) => {
  const Component = ({ children, language }) => {
    return (
      <SyntaxHighlighter language={language || null} style={docco}>
        {children}
      </SyntaxHighlighter>
    );
  };
  return (
    <div className="markdown-container">
      <ReactMarkdown
        components={{ code: Component }}
        remarkPlugins={[remarkGfm, remarkMath]}
        rehypePlugins={[rehypeKatex]}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
};

export default MdEditor;
