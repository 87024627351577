import React, { useContext } from "react";
import "../assets/login.scss";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { AuthContext } from "../context/authContext";
import { notification } from "antd";

export default function Login() {
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });

  const [err, setError] = useState(null);
  const { login, currentUser } = useContext(AuthContext);

  const navigate = useNavigate();
  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await login(inputs);
    } catch (err) {
      setError(err.response.data);
    }
    currentUser
      ? navigate("/home")
      : notification.open({
          message: "Warning",
          description: "Wrong Username or Password",
          type: "warning",
          placement: "topRight",
        });
  };
  return (
    <div className="auth">
      <h1>Login</h1>
      <form>
        <input
          type="text"
          placeholder="username"
          name="username"
          onChange={handleChange}
        />
        <input
          type="password"
          placeholder="password"
          name="password"
          onChange={handleChange}
        />
        <button onClick={handleSubmit}>Login</button>
        {err && <p>{err}</p>}
        <span>
          How about having an account? <Link to="/register">Register</Link>
        </span>
      </form>
    </div>
  );
}
