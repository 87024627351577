import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./pages/Home";
import Err from "./pages/Err";

import Single from "./pages/Single";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Write from "./pages/Write";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import "./assets/style.scss";
import First from "./pages/First";
import Tools from "./pages/Tools";
import axios from "axios";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div>
        <First />
        <Footer />
      </div>
    ),
  },

  {
    path: "/post/:id",
    element: (
      <div>
        <Navigation />
        <Single />
        <Footer />
      </div>
    ),
  },
  {
    path: "/write",
    element: (
      <div>
        <Navigation />
        <Write />
        <Footer />
      </div>
    ),
  },
  {
    path: "/tools",
    element: (
      <div>
        <Navigation />
        <Tools />
        <Footer />
      </div>
    ),
  },
  {
    path: "/home",
    element: (
      <div>
        <Navigation />
        <Home />
        <Footer />
      </div>
    ),
  },

  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "*",
    element: <Err />,
  },
]);

axios.defaults.withCredentials = true;
function App() {
  return (
    <main className="App">
      <div className="containter">
        <RouterProvider router={router} />
      </div>
    </main>
  );
}

export default App;
