import { React, useState, useEffect, useContext } from "react";
import "../assets/single.scss";
import { useNavigate, useLocation } from "react-router-dom";
import MdEditor from "../components/MdEditor";
import Menu from "../components/Menu.js";
import axios from "axios";
import moment from "moment/moment.js";
import { notification } from "antd";
import { AuthContext } from "../context/authContext.js";

axios.defaults.withCredentials = true;
export default function Single() {
  const [post, setPosts] = useState({});
  const loc = useLocation();
  const postId = loc.pathname.split("/")[2];
  const { currentUser } = useContext(AuthContext);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `https://my-blog-cpmores-gpvl.vercel.app/api/posts/${postId}`
        );
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [postId]);

  const handleDelete = async () => {
    try {
      const res = await axios.delete(
        `https://my-blog-cpmores-gpvl.vercel.app/api/posts/${postId}`
      );
      navigate("/home");
    } catch (err) {
      console.log(err);
    }
  };

  const navigate = useNavigate();
  const handleClick = async () => {
    if (currentUser && currentUser.id === post.uid) {
      navigate(`/write?edit=${postId}`, {
        state: { post: post },
      });
    } else {
      notification.open({
        message: "Warning",
        description:
          "You are not authorized to edit this post.Try allowing the third-party cookie",
        type: "warning",
        placement: "topRight",
      });
    }
  };

  const handleClick2 = async () => {
    if (currentUser && currentUser.id === post.uid) {
      handleDelete();
    } else {
      notification.open({
        message: "Warning",
        description: "You are not authorized to edit this post.",
        type: "warning",
        placement: "topRight",
      });
    }
  };

  return (
    <div className="single">
      <div className="content">
        <img src={post?.img} alt="something wrong with blake-cheek.jpg" />
        <div className="information">
          <div className="user">
            <img src={post?.userImg} alt="something wrong with your avatar" />
            <div className="info">
              <span className="name">{post.username}</span>
              <p>Posted {moment(post.date).fromNow()}</p>
            </div>
          </div>
          <div className="edit">
            <button className="editing" onClick={handleClick}>
              Edit
            </button>
            <span className="delete" onClick={handleClick2}>
              Delete
            </span>
          </div>
        </div>

        <article>
          <h1 style={{ "font-size": "35px" }}>{post.title}</h1>
          <MdEditor content={post.desc} />
        </article>
      </div>

      <div className="menu">
        <Menu cat={post.cat} />
      </div>
    </div>
  );
}
