import { createContext, useEffect, useState } from "react";
import axios from "axios";

export const AuthContext = createContext(null);

export default function AuthContextProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(() => {
    const storedUser = localStorage.getItem("user");
    try {
      return storedUser ? JSON.parse(storedUser) : null;
    } catch (e) {
      console.error("Error parsing JSON from localStorage:", e);
      return null;
    }
  });

  const login = async (inputs) => {
    try {
      const res = await axios.post(
        "https://my-blog-cpmores-gpvl.vercel.app/api/auth/login",
        inputs,
        {
          withCredentials: true, // 确保包含凭证
        }
      );
      setCurrentUser(res.data);
    } catch (err) {
      console.error("Login error:", err);
      // 处理错误
    }
  };

  const logout = async () => {
    try {
      await axios.post(
        "https://my-blog-cpmores-gpvl.vercel.app/api/auth/logout"
      );
      setCurrentUser(null);
    } catch (err) {
      console.error("Logout error:", err);
      // 处理错误
    }
  };

  useEffect(() => {
    try {
      if (currentUser) {
        localStorage.setItem("user", JSON.stringify(currentUser));
      } else {
        localStorage.removeItem("user");
      }
    } catch (e) {
      console.error("Error saving user to localStorage:", e);
    }
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{ login, logout, currentUser }}>
      {children}
    </AuthContext.Provider>
  );
}
