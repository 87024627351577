import React from "react";
import { notification } from "antd";
import "../assets/write.scss";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Input } from "antd";
import { useState } from "react";
import axios from "axios";
import { useLocation } from "react-router";
import "../assets/_InputQuill.scss";
import ReactMarkdown from "react-markdown";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import moment from "moment";
import { useContext } from "react";
import { AuthContext } from "../context/authContext.js";
import { useNavigate } from "react-router";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css"; // 确保导入 Katex 的样式文件

axios.defaults.withCredentials = true;

export default function Write() {
  const navigate = useNavigate();
  const location = useLocation();
  const { post } = location.state || {};
  const [title, setTitle] = useState(post?.title || "");
  const [cat, setCat] = useState(post?.cat || "");
  const [file, setFile] = useState(null);
  const [text, setText] = useState(post?.desc || "");
  const [uid, setUid] = useState();

  const { currentUser } = useContext(AuthContext);

  const upload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const res = await axios.post(
        "https://my-blog-cpmores-gpvl.vercel.app/api/upload",
        formData
      );
      return res.data.url;
    } catch (err) {
      console.log("Yes");
      return null;
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    setUid(currentUser.id);
    const imgUrl = await upload();
    console.log(imgUrl);
    console.log(currentUser);

    try {
      post
        ? await axios.put(
            `https://my-blog-cpmores-gpvl.vercel.app/api/posts/${post.id}`,
            {
              title,
              desc: text,
              cat,
              uid: currentUser.id,
              img: file ? imgUrl : post.img,
            }
          )
        : await axios.post(
            "https://my-blog-cpmores-gpvl.vercel.app/api/posts",
            {
              title,
              desc: text,
              cat,
              uid: currentUser.id,
              img: file ? imgUrl : "/postphotos/dog.jpg",
              date: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
            }
          );
      navigate("/home");
    } catch (err) {
      console.log(err);
    }
  };

  const Component = ({ children, language }) => {
    return (
      <SyntaxHighlighter language={language || null} style={docco}>
        {children}
      </SyntaxHighlighter>
    );
  };

  const handleClick = async (e) => {
    if (post) {
      if (currentUser.id === post.uid) {
        await handleSubmit(e);
      } else {
        notification.open({
          message: "Warning",
          description:
            "You are not authorized to edit this post.Try allowing the third-party cookie",
          type: "warning",
          placement: "topRight",
        });
      }
    } else {
      if (currentUser) await handleSubmit(e);
      else {
        notification.open({
          message: "Warning",
          description: "Please Login first!!!",
          type: "warning",
          placement: "topRight",
        });
      }
    }
  };
  return (
    <div className="write1">
      <div className="content">
        <div className="mark">
          <textarea
            className="textInput"
            value={text}
            name="desc"
            onChange={(e) => {
              setText(e.target.value);
            }}
          />
          <ReactMarkdown
            children={text}
            className="markdown-container"
            remarkPlugins={[remarkMath]}
            rehypePlugins={[rehypeKatex]}
            components={{
              code: Component,
            }}
          />
        </div>
      </div>
      <div className="menu">
        <div className="item">
          <Input
            placeholder="Enter title here"
            type="text"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{ width: 400, marginBottom: 16 }}
          />
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Category
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={cat}
              onChange={(e) => setCat(e.target.value)}
            >
              <FormControlLabel
                value="movie"
                control={<Radio />}
                label="movie"
              />
              <FormControlLabel value="web" control={<Radio />} label="web" />
              <FormControlLabel
                value="writeup"
                control={<Radio />}
                label="writeup"
              />
              <FormControlLabel
                value="poems"
                control={<Radio />}
                label="poems"
              />
              <FormControlLabel value="note" control={<Radio />} label="note" />
              <FormControlLabel value="math" control={<Radio />} label="math" />
            </RadioGroup>
          </FormControl>
          <div className="buttons">
            <button onClick={handleClick}>Upload</button>
            <input
              type="file"
              accept="image/png, image/jpeg, image/jpg"
              onChange={(e) => setFile(e.target.files[0])}
            ></input>
          </div>
        </div>
      </div>
    </div>
  );
}
