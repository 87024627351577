import React from "react";
import { Input, Space } from "antd";
const { Search } = Input;

const onSearch = (value, _e, info) => {
  if (value) {
    const googleSearchUrl = `https://www.google.com/search?q=${encodeURIComponent(
      value
    )}`;
    window.open(googleSearchUrl, "_blank");
  }
  console.log(info?.source, value);
};

const SearchBar = () => (
  <Space direction="vertical">
    <Search
      addonBefore="Google"
      placeholder="input search text"
      allowClear
      onSearch={onSearch}
      style={{
        width: "25vw",
        margin: "15px",
      }}
    />
  </Space>
);

export default SearchBar;
